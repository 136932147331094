import React from "react";
import NewsItem from "./NewsItem/NewsItem";

import { NavLink } from "react-router-dom";

import "./NewsList.css";

function NewsList(props) {
  return (
    <div className="NewsList">
      {props.newsList.map((news) =>
        news.pdf ? (
          <a href={`${news.pdf}`} target = "_blank" >
            <NewsItem
              key={news.id}
              h6Content={props.h6Content}
              h2Content={news.title}
              pContent={news.previewText}
              imageSrc={news.mainImage}
              date={news.date.slice(0,10)}
            />
          </a>
        ) : (
          <NavLink to={"/news/" + news.id}>
            <NewsItem
              key={news.id}
              h6Content={props.h6Content}
              h2Content={news.title}
              pContent={news.previewText}
              imageSrc={news.mainImage}
              date={news.date.slice(0,10)}
            />
          </NavLink>
        )
      )}
    </div>
  );
}

export default NewsList;
