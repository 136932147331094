import React, { useEffect, useState } from "react";
import NewsList from "./NewsList/NewsList";
import { useParams } from "react-router-dom";
import "./News.css";

// const toLeft = "<";
// const toRight = ">";

// function News(props) {
//     let params = useParams();
//     let [currentPagination, setCurrentPagination] = useState(1);
//     const [firstPagination, setFirstPagination] = useState(1);
//     const newsListLength = props.newsListArray.length;
//     useEffect(() => {
//         setCurrentPagination(1);
//     }, [params]);
//     useEffect(() => {
//         window.scrollTo({ top: 0 })
//     }, [currentPagination])
//     function goRight() {
//         if (currentPagination >= (newsListLength / 4)) { return }
//         if (currentPagination % 4 === 0) {
//             setFirstPagination(currentPagination + 1);
//         }
//         setCurrentPagination(currentPagination + 1);
//     }

//     function goLeft() {
//         if (currentPagination === 1) { return }
//         if (currentPagination % 4 === 1) {
//             setFirstPagination(currentPagination - 4);
//         }
//         setCurrentPagination(currentPagination - 1);
//     }

//     function itemClassname(ownPagination) {
//         if (newsListLength % 4 !== 0 && ownPagination >= (newsListLength / 4) + 1 ||
//             newsListLength % 4 == 0 && ownPagination >= (newsListLength / 4) + 1) {
//             return "unussedPaginationItem"
//         }
//         if (ownPagination === currentPagination) { return "currentPaginationItem" }
//         return "paginationItem";
//     }
//     return (
//         <div className="news">
//             <NewsList newsList={props.newsListArray.slice((currentPagination - 1) * 4, (currentPagination - 1) * 4 + 4)} h6Content = {props.h6Content} />
//             <div className="pagination">
//                 <div onClick={goLeft} className="paginationItem">
//                     <p className="paginationItemCharacter">{toLeft}</p>
//                 </div>
//                 <div onClick={() => setCurrentPagination(firstPagination)} className={`${itemClassname(firstPagination)}`}>
//                     <p className="paginationItemCharacter">{firstPagination}</p>
//                 </div>
//                 <div onClick={() => setCurrentPagination(firstPagination + 1)} className={`${itemClassname(firstPagination + 1)}`}>
//                     <p className="paginationItemCharacter">{firstPagination + 1}</p>
//                 </div>
//                 <div onClick={() => setCurrentPagination(firstPagination + 2)} className={`${itemClassname(firstPagination + 2)}`}>
//                     <p className="paginationItemCharacter">{firstPagination + 2}</p>
//                 </div>
//                 <div onClick={() => setCurrentPagination(firstPagination + 3)} className={`${itemClassname(firstPagination + 3)}`}>
//                     <p className="paginationItemCharacter">{firstPagination + 3}</p>
//                 </div>
//                 <div onClick={goRight} className="paginationItem">
//                     <p className="paginationItemCharacter">{toRight}</p>
//                 </div>
//             </div>
//         </div>
//     );
// }

function News(props) {
  let params = useParams();
  const newsListLength = props.newsListArray.length;

  return (
    <div className="news">
      <NewsList
        newsList={props.newsListArray}
        h6Content={props.h6Content}
      />
    </div>
  );
}

export default News;
