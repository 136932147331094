import React, { useEffect, useState } from "react";
import classes from "./Program1.module.css";
import publicationItems from "./Program1.service";
import PublicationList from "../../SharedComponents/PublicationList/PublicationList";
import Topic from "../../SharedComponents/Topic/Topic";
import HistoryOfCenterSlider from "../HistoryOfCenter/HistoryOfCenterSlider/HistoryOfCenterSlider";
import { useParams } from "react-router-dom";
// import parse from 'html-react-parser';
import { withNamespaces } from "react-i18next";
import Helmet from "react-helmet";

function Program1({ t }) {
  const [threeProjects, setThreeProjects] = useState([]);
  const [projects, setProjects] = useState(null);
  const [lastPublications, setLastPublications] = useState([]);
  const { projectId } = useParams();
  let lang = localStorage.getItem("lang");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}projects?_sort=id&_order=DESC&_start=0&_end=3&_lang=${lang}`
    )
      .then((res) => res.json())
      .then((data) => {
        setThreeProjects(data);
      });
  }, [projectId, lang]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}projects/${projectId}?_lang=${lang}`
    )
      .then((res) => res.json())
      .then((data) => {
        setProjects(data);
      });
  }, [projectId, lang]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}publications?_sort=id&_order=DESC&_start=0&_end=4&_lang=${lang}`
    )
      .then((res) => res.json())
      .then((data) => {
        setLastPublications(data);
      });
  }, [projectId, lang]);

  const createMarkup = () => {
    return { __html: projects.markedText };
  };

  if (projects) {
    return (
      <div>
        <Helmet>
          <title>{projects.title}</title>
        </Helmet>
        <Topic topic={projects.title} />
        <div className={classes.program1Content}>
          <div className={classes.markedText}>
            <div dangerouslySetInnerHTML={createMarkup()}></div>
          </div>
          <div className={classes.publications}>
            <PublicationList
              h3Content={t("MenuItemsTitle3")}
              type="projects"
              publications={threeProjects}
            />
            <div className={classes.secondPublication}>
              <PublicationList
                h3Content={t("MenuItemsTitle4")}
                publications={lastPublications}
              />
            </div>
          </div>
        </div>
        <div className={classes.program1Slider}>
          <HistoryOfCenterSlider data={projects.sliderImages} />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default withNamespaces()(Program1);
