import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
// import ScientificCouncilHeader from "../ScientificCouncil/ScientificCouncilHeader/ScientificCouncilHeader";

// import portfolioItems from "./Portfolio.service";

import styles from "./Portfolio.module.css";
import PortfolioMainItem from "./PortfolioMainItem/PortfolioMainItem";
import { withNamespaces } from "react-i18next";
import Topic from "../../SharedComponents/Topic/Topic"
import Helmet from "react-helmet";

function Portfolio({ t }) {


  const portfolioItems = [
    {
      id: 1,
      imageUrl: "/images/portfolioItemsIcons/community.png",
      title: t("item22"),
      route: "/portfolio/2",
    },
    {
      id: 2,
      imageUrl: "/images/portfolioItemsIcons/dashboard.png",
      title: t("item21"),
      route: "/portfolio/1",
    },
    {
      id: 3,
      imageUrl: "/images/portfolioItemsIcons/rating.png",
      title: t("item23"),
      route: "/portfolio/3",
    },
    {
      id: 4,
      imageUrl: "/images/portfolioItemsIcons/book.png",
      title: t("item24"),
      route: "/portfolio/4",
    },
    {
      id: 5,
      imageUrl: "/images/portfolioItemsIcons/composition.png",
      title: t("item25"),
      route: "/portfolio/5",
    },
  ];

  return (
    <div style={{ marginTop: 150 }}>
      <Helmet>
        <title>{t("MenuItemsTitle2")}</title>
      </Helmet>
      <Topic topic={t("MenuItemsTitle2")} />
      <div className={styles.itemsWrapper}>
        <div className={styles.itemsContainer}>
          {portfolioItems.map((item) => {
            return <PortfolioMainItem key={item.id} className={styles.portfolioItem} portFolioData={item} />;
          })}
        </div>
      </div>
      <AnalyticalBulletin />
    </div>
  );
}

export default withNamespaces()(Portfolio);
