import { NavLink } from "react-router-dom";
import styles from "./Footer.module.css";
import i18n from "../../../i18n";
import { withNamespaces } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import validator from "validator";
import axios from "axios";

function Footer({ t }) {
  let lang = localStorage.getItem("lang");
  const [projects, setProjects] = useState([]);
  // i18n.changeLanguage(selectedLang);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}projects?_sort=id&_order=DESC&_start=0&_end=4&_lang=${lang}`
    )
      .then((res) => res.json())
      .then((data) => {
        setProjects(data);
      });
  }, [lang]);

  const [emailError, setEmailError] = useState("");
  const postUser = async (value) => {
    if (validator.isEmail(value)) {
      let data = {
        mail: value,
      };
      // console.log(data);
      await axios({
        method: "POST",
        baseURL: `${process.env.REACT_APP_SERVER_URL}users`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });
      document.getElementById("userEmail").value = "";
      let successMessage = t("successSubscription");
      setEmailError(successMessage);
    } else {
      let errorMessage = t("emailErrorMessage");
      setEmailError(errorMessage);
    }
  };
  const about = [
    { title: t("item11"), route: "about-us/council-of-trustees" },
    { title: t("item12"), route: "about-us/scientific-council" },
    { title: t("item13"), route: "about-us/rules/1" },
    { title: t("item14"), route: "history-of-the-center" },
    { title: t("item15"), route: "about-us/center-staff" },
    { title: t("item16"), route: "about-us/donors-and-partners" },
  ];
  // const programs = [
  //   { title: "Ծրագիր 1", route: "programs/program1" },
  //   { title: "Ծրագիր 2", route: "programs/program2" },
  //   { title: "Ծրագիր 3", route: "programs/program3" },
  //   { title: "Ծրագիր 4", route: "programs/program4" },
  // ];
  const announcements = [
    {
      title: t("item41"),
      route: "publications/analytical-bulletin",
    },
    {
      title: t("item42"),
      route: "publications/policy-paper",
    },
    {
      title: t("item43"),
      route: "publications/sources-of-armenian-diplomacy",
    },
    { title: t("item44"), route: "publications/monographs" },
    { title: t("item45"), route: "publications/annual-reports" },
  ];
  const media = [
    { title: t("item51"), route: "media/cccs-media" },
    { title: t("item52"), route: "media/cccs-in-media" },
    { title: t("item53"), route: "media/media-cooperation" },
  ];
  const socialIcons = [
    {
      url: "/images/socialIcons/fb.png",
      link: "https://www.facebook.com/cccsysu",
    },
    // {
    //   url: "/images/socialIcons/fl.png",
    //   link: "https://www.facebook.com/cccsysu"
    // },
    {
      url: "/images/socialIcons/in.png",
      link: "https://www.instagram.com/cccsfoundation/",
    },
    {
      url: "/images/socialIcons/ln.png",
      link: "https://www.linkedin.com/in/cccs-foundation-2363a6221/",
    },
    {
      url: "/images/socialIcons/tw.png",
      link: "https://twitter.com/cccsfoundation",
    },
    {
      url: "/images/socialIcons/yt.png",
      link: "https://www.youtube.com/channel/UCkSzam8E2SZTOr2WdXEMYHg",
    },
  ];

  return (
    <div className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.contact}>
            <div className={styles.logo}>
              <img src={"/images/logo-blue.png"} alt="logo" />
              <div className={styles.logotext}>
                <NavLink
                  to="/"
                  className={styles.navlink}
                  style={{ textDecoration: "none", color: "#373435" }}
                >
                  <h2 className={styles.logoNameBig}>{t("logoName1")}</h2>
                  <h6 className={styles.logoNameSmall}>{t("logoName2")}</h6>
                </NavLink>
              </div>
            </div>
            <div className={styles.email}>
              <p className={styles.emailText}>{t("subscribtionText")}</p>
              <div className={styles.inputContainer}>
                <input
                  id="userEmail"
                  type="text"
                  placeholder={t("mail")}
                  className={styles.input}
                ></input>
                <div
                  className={styles.button}
                  onClick={() => {
                    let value = document.getElementById("userEmail").value;
                    postUser(value);
                  }}
                >
                  <img
                    className={styles.btnImage}
                    src={"/images/right.png"}
                    alt=""
                  ></img>
                </div>
              </div>
              <p style={{ color: "black", marginTop: "15px" }}>{emailError}</p>
            </div>
          </div>
          <div className={styles.about}>
            <NavLink to={`/`} className={styles.aboutHeader}>
              {t("MenuItemsTitle1")}
            </NavLink>
            {about.map((item) => {
              return (
                <NavLink
                  key={item.route}
                  to={`/${item.route}`}
                  className={styles.aboutLink}
                >
                  <p key={item.route} className={styles.aboutText}>
                    {item.title}
                  </p>
                </NavLink>
              );
            })}
          </div>
          <div className={styles.programs}>
            <NavLink to={`/projects`} className={styles.aboutHeader}>
              {t("MenuItemsTitle3")}
            </NavLink>
            {projects.map((item) => {
              return (
                <NavLink
                  key={item.id}
                  to={`/projects/${item.id}`}
                  className={styles.aboutLink}
                >
                  <p key={item.id} className={styles.aboutText}>
                    {item.title}
                  </p>
                </NavLink>
              );
            })}
          </div>
          <div className={styles.announcements}>
            <NavLink to={`/publications`} className={styles.aboutHeader}>
              {t("MenuItemsTitle4")}
            </NavLink>
            {announcements.map((item) =>
              item.route == "publications/analytical-bulletin" ? (
                <a
                  href="https://analytical-bulletin.cccs.am/"
                  key={item.route}
                  className={styles.aboutLink}
                >
                  <p key={item.route} className={styles.aboutText}>
                    {item.title}
                  </p>
                </a>
              ) : (
                <NavLink
                  key={item.route}
                  to={`/${item.route}`}
                  className={styles.aboutLink}
                >
                  <p key={item.route} className={styles.aboutText}>
                    {item.title}
                  </p>
                </NavLink>
              )
            )}
          </div>
          <div className={styles.media}>
            <NavLink to={`/media`} className={styles.aboutHeader}>
              {t("MenuItemsTitle5")}
            </NavLink>
            {media.map((item) => {
              return (
                <NavLink
                  key={item.route}
                  to={`/${item.route}`}
                  className={styles.aboutLink}
                >
                  <p key={item.route} className={styles.aboutText}>
                    {item.title}
                  </p>
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.socialIcons}>
          <div className={styles.socilaIconsContainer}>
            {socialIcons.map((icon) => {
              return (
                <a href={icon.link} key={`${icon.url}`} target="_blank">
                  <img className={styles.icon} src={`${icon.url}`}></img>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Footer);
