import {React, useState, useEffect} from "react";
import portfolioItems from "./PortfolioDetails.service";
import PortfolioItem from "./PortfolioItem/PortfolioItem";
import classes from "./PortfolioDetails.module.css";
import { withNamespaces } from "react-i18next";

const PortfolioDetails = () => {
  const [portfolioItems, setPortfolioItems] = useState([]);
  const lang = localStorage.getItem("lang");

  useEffect(async() => {
    let result = await fetch(`${process.env.REACT_APP_SERVER_URL}theories?_lang=${lang}`, 
    {
      method: "GET",
    }
    );
    result = await result.json();
    setPortfolioItems(result);
  }, [])

  return (
    <div className =  {classes.wrapper}>
      {portfolioItems.map((portfolioItem, index) => {
        return <PortfolioItem portfolioItemData = {portfolioItem} />;
      })}
    </div>
  );
}

export default withNamespaces()(PortfolioDetails);
