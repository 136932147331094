import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Spinner from "../../../SharedComponents/Spinner/Spinner";

function RulesContent() {
  const params = useParams();

  let [rules, setRules] = useState([]);


  let lang = localStorage.getItem("lang");
  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}rules?_sort=id&_order=ASC&_lang=${lang}`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    // console.log(result);
    setRules(result);
  }, [lang]);

  let [currentRules, setCurrentRules] = useState(rules[0]);
  useEffect(() => {
    // console.log(rules);
    setCurrentRules(rules.find((rule) => rule.id == params.rulesId));
  }, [rules, params]);

  const createMarkup = () => {
    return { __html: currentRules.markedText };
  }

  return currentRules ? <div dangerouslySetInnerHTML={createMarkup()} ></div> : <Spinner />;
}

export default withNamespaces()(RulesContent);
