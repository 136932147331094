import { React, useState, useEffect } from "react";
// import ScientificCouncilHeader from "../../ScientificCouncil/ScientificCouncilHeader/ScientificCouncilHeader";
import Topic from "../../../SharedComponents/Topic/Topic";

import classes from "./ProgramsMain.module.css";
// import programs from "./ProgramsMain.service";
import ProgramBox from "./ProgramBox/ProgramBox";

import { withNamespaces } from "react-i18next";
import Spinner from "../../../SharedComponents/Spinner/Spinner";

function ProgramsMain({ t }) {
  let slidesNumber;
  if (window.innerWidth <= 1200 && window.innerWidth >= 800) {
    slidesNumber = 2;
  } else if (window.innerWidth < 800) {
    slidesNumber = 1;
  } else {
    slidesNumber = 4;
  }

  const [programs, setPrograms] = useState([]);
  const [currentPrograms, setCurrentPrograms] = useState(
    programs.slice(0, slidesNumber)
  );
  let lang = localStorage.getItem("lang");
  useEffect(() => {
    async function fetchData() {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}projects?_sort=id&_order=DESC&_start=0&_end=8&type=&_lang=${lang}`,
        {
          method: "GET",
        }
      );
      result = await result.json();
      setPrograms(result);
      setCurrentPrograms(result.slice(0, slidesNumber));
    }
    fetchData();
  }, [lang, slidesNumber]);

  let slidesPageCount = programs.length / slidesNumber;
  const [index, setIndex] = useState(0);

  const showNext = () => {
    if (index !== slidesPageCount - 1) {
      setCurrentPrograms(
        programs.slice(
          index * slidesNumber + slidesNumber,
          index * slidesNumber + 2 * slidesNumber
        )
      );
      setIndex(index + 1);
    } else {
      setCurrentPrograms(programs.slice(0, slidesNumber));
      setIndex(0);
    }
  };

  const showPrev = () => {
    if (index !== 0) {
      setCurrentPrograms(
        programs.slice(
          index * slidesNumber - slidesNumber,
          index * slidesNumber
        )
      );
      setIndex(index - 1);
    } else {
      setCurrentPrograms(
        programs.slice(
          slidesPageCount * slidesNumber - slidesNumber,
          slidesPageCount * slidesNumber
        )
      );
      setIndex(slidesPageCount - 1);
    }
  };
  return programs.length === 8 ? (
    <div>
      <Topic topic={t("MenuItemsTitle3")} />

      <div className={classes.arrowsContainer}>
        <div className={classes.arrowLeft} onClick={showPrev}>
          <img src={process.env.PUBLIC_URL + "/images/left.png"} alt="" />
        </div>
        <div className={classes.arrowRight} onClick={showNext}>
          <img src={process.env.PUBLIC_URL + "/images/right.png"} alt="" />
        </div>
      </div>

      <div className={classes.programsContainer}>
        {currentPrograms.map((program, index) => {
          return (
            <ProgramBox
              title={program.title}
              image={program.mainImage}
              text={program.previewText}
              id={program.id}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default withNamespaces()(ProgramsMain);
