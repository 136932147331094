import React from 'react';
import ExWorkers from './ExWorkers';
import { withNamespaces } from 'react-i18next';

function Students() {
    return (
        <>
           <ExWorkers endpoint = "students" header= "students" /> 
        </>
    )
}

export default Students;
