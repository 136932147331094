import styles from "./MemberCard.module.css";
import SocialLinks from "./SocialLinks/SocialLinks";

function MemberCard({ memberData }) {
  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <img
          src={
            memberData.image
          }
        ></img>
      </div>
      <div className={styles.textContainer}>
        <p className={styles.name}>{memberData.name}</p>
        <p className={styles.position}>{memberData.position}</p>
        <p className={styles.bio}>{memberData.bio}</p>
        <p className={styles.email}>{memberData.email}</p>
        { (memberData.linkedinLink || memberData.orcidLink) ? <SocialLinks linkedinLink = {memberData.linkedinLink}  orcidLink = {memberData.orcidLink} className = {styles.socialLinks}  /> : null}
      </div>
     
    </div>
  );
}

export default MemberCard;

