import { v4 as uuidv4 } from "uuid";

const analyticalBulletins = [
  {
    id: uuidv4(),
    imageURL: "analytical1.png",
  },
  {
    id: uuidv4(),
    imageURL: "analytical2.png",
  },
  {
    id: uuidv4(),
    imageURL: "analytical3.png",
  },
  {
    id: uuidv4(),
    imageURL: "analytical4.png",
  },
  {
    id: uuidv4(),
    imageURL: "analytical5.png",
  },
  {
    id: uuidv4(),
    imageURL: "analytical2.png",
  },
  {
    id: uuidv4(),
    imageURL: "analytical3.png",
  },
  {
    id: uuidv4(),
    imageURL: "analytical1.png",
  },
  {
    id: uuidv4(),
    imageURL: "analytical3.png",
  },
  {
    id: uuidv4(),
    imageURL: "analytical4.png",
  },
];

export default analyticalBulletins;
