import React from "react";
import { NavLink } from "react-router-dom";
import Publication from "./Publication/Publication";
import "./PublicationList.css";

function PublicationList(props) {
  if (props.type == 'projects') {
    return (
      <div className="publicationList">
        <h3 className="h3">{props.h3Content}</h3>
        {props.publications.map((p, index) =>
        (
          <NavLink to={"/projects/" + p.id}>
            <Publication
              key={index}
              h4Content={p.title}
              imageSrc={`${p.mainImage}`}
            />
          </NavLink>
        )
        )}
      </div>
    );
  } else {
    return (
      <div className="publicationList">
        <h3 className="h3">{props.h3Content}</h3>
        {props.publications.map((p, index) =>
          p.pdf ? (
            <a
              href={`${p.pdf}`}
              target="_blank"
            >
              <Publication
                key={index}
                h4Content={p.title}
                imageSrc={`${p.mainImage}`}
              />
            </a>
          ) : (
            <NavLink to={"/news/" + p.id}>
              <Publication
                key={index}
                h4Content={p.title}
                imageSrc={`${p.mainImage}`}
              />
            </NavLink>
          )
        )}
      </div>
    );
  }
}

export default PublicationList;
