import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Components/SharedComponents/Header";
import Home from "./Components/Pages/Home/Home";
import CouncilOfTrustees from "./Components/Pages/CouncilOfTrustees/CouncilOfTrustees";
import ScientificCouncil from "./Components/Pages/ScientificCouncil/ScientificCouncil";
import DowntownStaff from "./Components/Pages/DowntownStaff/DownTownStaff";
import Rules from "./Components/Pages/Rules/Rules";
import Footer from "./Components/SharedComponents/Footer/Footer";
import Portfolio from "./Components/Pages/Portfolio/Portfolio";
import Programs from "./Components/Pages/Programs/Programs";
import Announcements from "./Components/Pages/Announcements/Announcements";
import Media from "./Components/Pages/Media/Media";
import PortfolioDetailsPage from "./Components/Pages/PortfolioDetailsPage/PortfolioDetailsPage";
import HistoryOfCenter from "./Components/Pages/HistoryOfCenter/HistoryOfCenter";
import ScrollToTop from "./Components/ScrollToTop";
import AboutUs from "./Components/Pages/AboutUs/AboutUs";
import PolicyPaper from "./Components/Pages/PolicyPaper/PolicyPaper";
import NewsPage from "./Components/Pages/NewsPage/NewsPage";
import CCCSMedia from "./Components/Pages/CCCSMedia/CCCSMedia";
import DonorsAndPartners from "./Components/Pages/DonorsAndPartners/DonorsAndPartners";
import Program1 from "./Components/Pages/Program1/Program1";
import NewsDetails from "./Components/Pages/NewsDetails/NewsDetails";
import SearchResults from "./Components/SharedComponents/SearchResults/SearchResults";

function App() {
  localStorage.setItem("lang", "arm");
  return (
    <div style={{ marginTop: 75 }}>
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route
            path="/about-us/council-of-trustees"
            component={CouncilOfTrustees}
          />
          <Route
            path="/about-us/scientific-council"
            component={ScientificCouncil}
          />
          <Route path="/about-us/center-staff" component={DowntownStaff} />
          <Route path="/about-us/rules/:rulesId" component={Rules} />
          <Route
            path="/about-us/history-of-the-center"
            component={HistoryOfCenter}
          />

          <Route
            path="/about-us/donors-and-partners"
            component={DonorsAndPartners}
          />

          <Route path="/about-us" component={AboutUs} />

          <Route exact path="/portfolio" component={Portfolio} />
          <Route
            path="/portfolio/:portfolioId"
            component={PortfolioDetailsPage}
          />
          <Route exact path="/projects" component={Programs} />
          <Route path="/projects/:projectId" component={Program1} />

          <Route path="/publications" component={Announcements} exact />

          {/* <Route path="/publications/analytical-bulletin" component={Home} /> */}

          <Route path="/publications/:pubId" component={PolicyPaper} />
          <Route path="/news/:newsId" component={NewsDetails} />
          <Route path="/news/" component={NewsPage} />

          <Route path="/media/:mediaId" component={CCCSMedia} />
          <Route path="/media" component={Media} />

          <Route path="/history-of-the-center" component={HistoryOfCenter} />

          <Route path="/search-results" component={SearchResults} />

          <Route exact path="/" component={Home} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
