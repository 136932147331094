"use strict"

module.exports = {
    firstPublicationList: {
        h3Content: "Ծրագրեր",
        publications: [
            {
                header: "Բախում և համերաշխություն",
                imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
            },
            {
                header: "Բախում և համերաշխություն",
                imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
            },
            {
                header: "Բախում և համերաշխություն",
                imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
            },
        ],
    },
    secondPublicationList: {
        h3Content: "Հրապարակումներ",
        publications: [
            {
                header: "Բախում և համերաշխություն",
                imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
            },
            {
                header: "Բախում և համերաշխություն",
                imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
            },
            {
                header: "Բախում և համերաշխություն",
                imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
            },
            {
                header: "Բախում և համերաշխություն",
                imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
            },
        ],
    },
}