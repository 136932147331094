// import { useState, useEffect } from "react";
// import MemberCard from "./memberCard/MemberCard";

// import CouncilOfTrusteesHeader from "./ConcilOfTrusteesHeader/CouncilOfTrusteesHeader";

// import Topic from "../../SharedComponents/Topic/Topic";
// import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";

// import styles from "./CouncilOfTrustees.module.css";

// import membersOfTrustees from "./CouncilOfTrustees.service";

// import { withNamespaces } from "react-i18next";
import ScientificCouncil from "../ScientificCouncil/ScientificCouncil";
import { withNamespaces } from "react-i18next";


function CouncilOfTrustees({t}) {

  // let [trustees, setTrustees] = useState([]);

  // let lang = localStorage.getItem("lang");

  // useEffect(async () => {
  //   let result = await fetch(
  //     `${process.env.REACT_APP_SERVER_URL}council-of-trustees?_sort=id&_order=ASC&_lang=${lang}`,
  //     {
  //       method: "GET",
  //     }
  //   );
  //   result = await result.json();
  //   console.log("5552", result);
  //   setTrustees(result);
  // }, [lang]);

  return (
    <ScientificCouncil
      headerText={t("item11")}
      endpoint = "council-of-trustees"
    />
  );
}

export default withNamespaces()(CouncilOfTrustees);
