import styles from "./AnalyticalBulletinSlider.module.css";

import analyticalBulletins from "../AnalyticalBulletin.service";
import { useEffect, useState } from "react";
import Spinner from "../../Spinner/Spinner";

function AnalyticalBulletinSlider() {
  const [bulletins, setBulletins] = useState([]);
  useEffect(async () => {
    let result = await fetch(`${process.env.REACT_APP_SERVER_URL}bulletin?_sort=id&_order=ASC&_start=0&_end=10`, {
      method: "GET",
    });
    result = await result.json();
    setBulletins(result);
  }, []);

  const [index, setIndex] = useState(0);
  const [sliderItemCount, setSliderItemCount] = useState(5);
  let slidesCount = Math.trunc(bulletins.length / sliderItemCount);
  const currentBulletins = bulletins.slice(
    index * sliderItemCount,
    index * sliderItemCount + sliderItemCount
  );

  useEffect(() => {
    if (window.innerWidth < 1250 && window.innerWidth > 850) {
      setSliderItemCount(3);
    } else if (window.innerWidth < 850) {
      setSliderItemCount(1);
    }
  }, []);

  return (
    <div>
      {bulletins.length !== 0 ? (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.arrowsContainer}>
              <div
                className={styles.arrowLeft}
                onClick={() => {
                  setIndex((index - 1 + slidesCount) % slidesCount);
                }}
              >
                <img src={process.env.PUBLIC_URL + "/images/left.png"} />
              </div>
              <div
                className={styles.arrowRight}
                onClick={() => {
                  setIndex((index + 1) % slidesCount);
                }}
              >
                <img src={process.env.PUBLIC_URL + "/images/right.png"} />
              </div>
            </div>
            <div className={styles.imageContainer}>
              {currentBulletins.map((bulletin) => {
                return (
                  <a href = {`${bulletin.linkTo}` } target = "_blank" >
                    <img
                      key={bulletin._id}
                      className={styles.bulletinImage}
                      src={`${bulletin.imageURL}`}
                    ></img>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default AnalyticalBulletinSlider;
