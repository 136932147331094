import React from 'react';
import "./Topic.css";

function Topic(props) {
    return (
        <div className = "topic"> 
            <h1 className = "h1">{props.topic}</h1>
            <hr className = "hr"></hr>
        </div>
    );
}

export default Topic;