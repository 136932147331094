import { v4 as uuidv4 } from "uuid";
const dataSliderHistory = [
    {
      id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
    },
    {
      id: uuidv4(),
      imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
    },
    {
      id: uuidv4(),
      imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

    },
    {
      id: uuidv4(),
      imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
    },
    {
      id: uuidv4(),
      imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
    },
    {
      id: uuidv4(), 
      imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

    },
  ];
  
  export default dataSliderHistory;
  
