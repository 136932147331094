import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import classes from "./MapComponent.module.css";
import { withNamespaces } from "react-i18next";

function MapComponent({t}) {
  return (
    <div className={classes.mymapbox}>
      <MapContainer className={classes.mymap} center={[40.204, 44.525]} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[40.189, 44.515]}>
          <Popup>
            {t("We are here")}
          </Popup>
        </Marker>
      </MapContainer>

      <div className = {classes.maptext} >
        <div className = {classes.address} >
         { t("address")}
        </div>
        <div className = {classes.address} >
          +374 60 71 05 94
        </div>
       
      </div>
    </div>

    
  );
}

export default withNamespaces()(MapComponent);
