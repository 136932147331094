import React from "react";
import classes from "./PortfolioItem.module.css";
import { NavLink } from "react-router-dom";
import { withNamespaces } from "react-i18next";

function PortfolioItem({ portfolioItemData, t }) {
  let gradientStyle;
  let title;
  switch (portfolioItemData.id) {
    case 1:
      gradientStyle = classes.item1;
      title = t("item21");
      break;
    case 2:
      gradientStyle = classes.item2;
      title = t("item22");
      break;
    case 3:
      gradientStyle = classes.item3;
      title = t("item23");
      break;
    case 4:
      gradientStyle = classes.item4;
      title = t("item24");
      break;
    case 5:
      gradientStyle = classes.item5;
      title = t("item25");
      break;
  }

  return (
    <NavLink className={classes.portfolioItem} to = {`portfolio/${portfolioItemData.id}`} >
      <div className={gradientStyle} ></div>  
      <img
        src={portfolioItemData.mainImage}

      />
      <div className={classes.title}>{title}</div>  
    </NavLink>
  );
}

export default withNamespaces()(PortfolioItem);
