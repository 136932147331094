import ScientificCouncil from "../ScientificCouncil/ScientificCouncil";
import { withNamespaces } from "react-i18next";

// import membersOfDowntown from "./DownTownStaff.service";
function DowntownStaff({t}) {
  // let lang = localStorage.getItem("lang");
  // let [members, setMembers] = useState([]);
  // useEffect(async () => {
  //   let result = await fetch(
  //     `${process.env.REACT_APP_SERVER_URL}staff?_sort=id&_order=ASC&_lang=${lang}`,
  //     {
  //       method: "GET",
  //     }
  //   );
  //   result = await result.json();
  //   setMembers(result);
  // }, [lang]);
  return (
    <ScientificCouncil
      headerText={t("item15")}
      endpoint = "staff"
    />
  );
}

export default withNamespaces()(DowntownStaff);
