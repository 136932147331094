import {React,useState, useEffect} from "react";
import classes from "./DonorsAndPartners.module.css";
import Topic from "../../SharedComponents/Topic/Topic";
// import donors from "./Donors.service";
import partners from "./Partners.service";
import { withNamespaces } from "react-i18next";
import Spinner from "../../SharedComponents/Spinner/Spinner";
import Helmet from "react-helmet";

function DonorsAndPartners({t}) {

  const [donors, setDonors] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}donors?_sort=id&_order=DESC`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    setDonors(result);
  }, []);
  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}partners?_sort=id&_order=DESC`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    setPartners(result);
  }, []);


  return ( donors && partners ?
    <div className={classes.pageContainer}>
      <Helmet>
        <title>{t("donors")}</title>
      </Helmet>
      <div className={classes.donors}>
        <Topic topic={t("donors")} />
        <div className={classes.logos}>
          {donors.map((donor) => {
            return (
              <a
                className={classes.logoContainer}
                href={donor.link}
                target="_blank"
              >
                <img src={donor.image} className={classes.donorLogo} />
              </a>
            );
          })}
        </div>
      </div>
      <div className={classes.partners}>
        <Topic topic={t("partners")} />
        <div className={classes.logos}>
          {partners.map((donor) => {
            return (
              <a
                className={classes.logoContainer}
                href={donor.link}
                target="_blank"
              >
                <img src={donor.image} className={classes.donorLogo} />
              </a>
            );
          })}
        </div>
      </div>
    </div> : <Spinner />
  );
}

export default withNamespaces()(DonorsAndPartners);
