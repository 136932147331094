import React from "react";
import styles from "../Portfolio/Portfolio.module.css";
import PortfolioMainItem from "../Portfolio/PortfolioMainItem/PortfolioMainItem";
import { withNamespaces } from "react-i18next";
import Topic from "../../SharedComponents/Topic/Topic";
import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
import Helmet from "react-helmet";

function Media({ t }) {

  const mediaItems = [
    {
      id: 1,
      imageUrl: "/images/mediaIcons/1.png",
      title: t("item51"),
      route: "/media/cccs-media",
    },
    {
      id: 2,
      imageUrl: "/images/mediaIcons/2.png",
      title: t("item52"),
      route: "/media/cccs-in-media",
    },
    {
      id: 3,
      imageUrl: "/images/mediaIcons/3.png",
      title: t("item53"),
      route: "/media/media-cooperation",
    },
  ];
  return (
    <div style={{ marginTop: 150 }}>
      <Helmet>
        <title>{t("MenuItemsTitle5")}</title>

      </Helmet>
      <Topic topic={t("MenuItemsTitle5")} />
      <div className={styles.itemsWrapper}>
        <div className={styles.itemsContainer}>
          {mediaItems.map((item) => {
            return (
              <PortfolioMainItem
                key={item.id}
                className={styles.portfolioItem}
                portFolioData={item}
              />
            );
          })}
        </div>
      </div>
      <AnalyticalBulletin />
    </div>
  );
}

export default withNamespaces()(Media);
