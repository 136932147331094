import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
} from "react-router-dom";
import analyticalBulletins from "./AnalyticalBulletin/AnalyticalBulletin.service";
import classes from "./DropdownBox.module.css";

function DropdownBox(props) {
  const listItems = props.listItems;
  const dropdownType = props.dropdownType;

  // const defineProjectTitle = (project) => {
  //   if (props.langToShow == 'En') {
  //     return project.translations.hy.title;
  //   } else {
  //     return project.translations.en.title;
  //   }
  // }

  if (dropdownType == "projects") {
    return (
      <div className={classes.dropdown} onClick={props.onClick}>
        <ul>
          {listItems.map((listItem) => (
            <NavLink to={`/${dropdownType}/${listItem.id}`}>
              <li key={listItem.id}>{listItem.title}</li>
            </NavLink>
          ))}
        </ul>
      </div>
    );
  } else {
    return (
      <div className={classes.dropdown} onClick={props.onClick}>
        <ul>
          {listItems.map((listItem) =>
            listItem.route == "publications/analytical-bulletin" ? (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "http://analytical-bulletin.cccs.am/";
                }}
              >
                <li key={listItem.title}>{listItem.title}</li>
              </a>
            ) : (
              <NavLink to={`/${listItem.route}`}>
                <li key={listItem.title}>{listItem.title}</li>
              </NavLink>
            )
          )}
        </ul>
      </div>
    );
  }
}

export default DropdownBox;
