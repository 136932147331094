import React from "react";
import classes from "./PublicationItem.module.css";
import KnowMoreButton from "../../../../SharedComponents/KnowMoreButton/KnowMoreButton";

function PublicationItem({ imgUrl, header, text }) {
  return (
    <div className={classes.publItem}>
      <div className={classes.imgSide}>
        <img src={imgUrl} className={classes.publImg} alt="" />
      </div>
      <div className={classes.textSide}>
        <div className={classes.header}>{header}</div>
        <div className={classes.text}>{text}</div>
        <div className={classes.buttonMore}>
          <KnowMoreButton />
        </div>
      </div>
    </div>
  );
}

export default PublicationItem;
