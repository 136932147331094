import MemberCard from "./memberCard/MemberCard";
import {react, useState, useEffect} from "react";
import Topic from "../../SharedComponents/Topic/Topic";
import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
import styles from "./ScientificCouncil.module.css";
import Spinner from "../../SharedComponents/Spinner/Spinner";
import { withNamespaces } from "react-i18next";
import ExWorkers from "../DowntownStaff/ExWorkersAndStudents/ExWorkers";
import Students from "../DowntownStaff/ExWorkersAndStudents/Students";
import Helmet from "react-helmet";



// import membersOfScience from "./ScientificCouncil.service";

function ScientificCouncil({
  t,
  endpoint = "scientific-council",
  headerText = "item12",
}) {

  let lang = localStorage.getItem("lang");
  let [members, setMembers] = useState([]);
  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}${endpoint}?_sort=id&_order=ASC&_lang=${lang}`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    setMembers(result);
  }, [lang]);
  return (
    <div>
      {members ? <div className={styles.wrapper}>
        <Helmet>
          <title>{t(headerText)}</title>
        </Helmet>
        <div className={styles.constainer}>
          <Topic topic={t(headerText)} />
          <div className={styles.memberContainer}>
            {members.map((member) => {
              return <MemberCard key={member.id} memberData={member} />;
            })}
          </div>

          {endpoint === "staff" ? <>
            <ExWorkers />
            <Students />
          </> : null}
        </div>
      </div> : <Spinner />}
      <AnalyticalBulletin />
    </div>
  );
}

export default withNamespaces()(ScientificCouncil);
