import { NavLink } from "react-router-dom";
import styles from "./PortfolioDetailsPageRightMenu.module.css";
import {React, useState, useEffect} from "react";
import { withNamespaces } from "react-i18next";
import PublicationList from "../../../SharedComponents/PublicationList/PublicationList";
import Spinner from "../../../SharedComponents/Spinner/Spinner";

// import {
//   programs,
//   RightSidePublications,
// } from "./PortfolioDetailsPageRightMenu.service";

function PortfolioDetailsPageRightMenu({t, projectType}) {

  const [lastPublications, setLastPublications] = useState([]);

  const [lastProjects, setLastProjects] = useState([]);

  let lang = localStorage.getItem("lang");
  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}publications?_sort=id&_order=DESC&_start=0&_end=4&_lang=${lang}`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    setLastPublications(result);
  }, [lang]);

  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}projects?_sort=id&_order=DESC&_start=0&_end=3&type=${projectType}&_lang=${lang}`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    setLastProjects(result);
  }, [lang, projectType]);
  
  
  return (
    <div>
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.programsContainer}>
              <li className={styles.listTitle}>{t("MenuItemsTitle3")}</li>
              {lastProjects ? lastProjects.map((item) => {
                return (
                  <li key={item.id} className={styles.listItem}>
                    <NavLink to={`/projects/${item.id}`} className={styles.linkItem}>
                      <div className={styles.itemContainer}>
                        <img
                          className={styles.itemImage}
                          src={item.mainImage}
                        ></img>
                        <p className={styles.itemTitle}>{item.title}</p>
                      </div>
                    </NavLink>
                  </li>
                );
              }) : <Spinner />}
            </div>
            {/* <li className={styles.listTitle}>Հրապարակումներ</li>
            {RightSidePublications.map((item) => {
              return (
                <li key={item.id} className={styles.listItem}>
                  <NavLink to={`/portfolio`} className={styles.linkItem}>
                    <div className={styles.itemContainer}>
                      <img
                        className={styles.itemImage}
                        src={item.imageSrc}
                      ></img>
                      <p className={styles.itemTitle}>{item.header}</p>
                    </div>
                  </NavLink>
                </li>
              );
            })} */}

            <PublicationList
                h3Content={t("MenuItemsTitle4")}
                publications={lastPublications} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(PortfolioDetailsPageRightMenu);
