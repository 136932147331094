import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DropdownBox from "./DropdownBox";

import { withNamespaces } from "react-i18next";
import i18n from "../../i18n";
// import { useLocation } from 'react-router-dom';

import classes from "./Header.module.css";

// import programItems from "../../sharedInformation/ProjectsData.service";

import axios from "axios";

function Header({ t }) {
  const [showDropdown1, setShowDropdown1] = useState(false);

  const handleMouseEnter1 = () => setShowDropdown1(true);

  const handleMouseLeave1 = () => setShowDropdown1(false);

  const [showDropdown2, setShowDropdown2] = useState(false);

  const handleMouseEnter2 = () => setShowDropdown2(true);

  const handleMouseLeave2 = () => setShowDropdown2(false);

  const [showDropdown3, setShowDropdown3] = useState(false);

  const handleMouseEnter3 = () => setShowDropdown3(true);

  const handleMouseLeave3 = () => setShowDropdown3(false);

  const [showDropdown4, setShowDropdown4] = useState(false);

  const handleMouseEnter4 = () => setShowDropdown4(true);

  const handleMouseLeave4 = () => setShowDropdown4(false);

  const [showDropdown5, setShowDropdown5] = useState(false);

  const handleMouseEnter5 = () => setShowDropdown5(true);

  const handleMouseLeave5 = () => setShowDropdown5(false);

  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const handleIconClick = () => setShowDropdownMenu(!showDropdownMenu);

  const [langToShow, setLangToShow] = useState("En");
  let selectedLang;
  const handleLanguageChange = () => {
    if (langToShow === "En") {
      setLangToShow("Հայ");
      localStorage.setItem("lang", "en");
      selectedLang = localStorage.getItem("lang");
      i18n.changeLanguage(selectedLang);
    } else {
      setLangToShow("En");
      localStorage.setItem("lang", "arm");
      selectedLang = localStorage.getItem("lang");
      i18n.changeLanguage(selectedLang);
    }

    // i18n.changeLanguage(x);
    // console.log('>>> ', i18n.language);
    // debugger;
    // window.location.reload();
  };
  const [inputValue, setInputValue] = useState("");
  const handleSearchInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearchClick = () => {
    localStorage.setItem("search", inputValue);
    window.scrollTo(0, 0);
    setInputValue("");
  };

  const MenuItemsArr = [
    { title: t("MenuItemsTitle1"), route: "about-us" },
    { title: t("MenuItemsTitle2"), route: "portfolio" },
    { title: t("MenuItemsTitle3"), route: "projects" },
    { title: t("MenuItemsTitle4"), route: "publications" },
    { title: t("MenuItemsTitle5"), route: "media" },
    { title: t("MenuItemsTitle6"), route: "news" },
  ];

  const arr1 = [
    { title: t("item11"), route: "about-us/council-of-trustees" },
    { title: t("item12"), route: "about-us/scientific-council" },
    { title: t("item13"), route: "about-us/rules/1" },
    { title: t("item14"), route: "about-us/history-of-the-center" },
    { title: t("item15"), route: "about-us/center-staff" },
    { title: t("item16"), route: "about-us/donors-and-partners" },
  ];

  const arr2 = [
    {
      title: t("item21"),
      route: "portfolio/1",
    },
    {
      title: t("item22"),
      route: "portfolio/2",
    },
    {
      title: t("item23"),
      route: "portfolio/3",
    },
    { title: t("item24"), route: "portfolio/4" },
    { title: t("item25"), route: "portfolio/5" },
  ];
  // const arr3 = [
  //   { title: "Ծրագիր 1", route: "programs/program1" },
  //   { title: "Ծրագիր 2", route: "programs/program2" },
  //   { title: "Ծրագիր 3", route: "programs/program3" },
  //   { title: "Ծրագիր 4", route: "programs/program4" },
  // ];

  // const arr3 = programItems.slice(programItems.length - 4, programItems.length);

  const arr4 = [
    {
      title: t("item41"),
      route: "publications/analytical-bulletin",
    },
    {
      title: t("item42"),
      route: "publications/policy-paper",
    },
    {
      title: t("item43"),
      route: "publications/sources-of-armenian-diplomacy",
    },
    { title: t("item44"), route: "publications/monographs" },
    { title: t("item45"), route: "publications/annual-reports" },
  ];
  const arr5 = [
    { title: t("item51"), route: "media/cccs-media" },
    { title: t("item52"), route: "media/cccs-in-media" },
    { title: t("item53"), route: "media/cccs-media-collaboration" },
  ];

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    let lang = "";
    if (langToShow == "En") {
      lang = "arm";
    } else {
      lang = "en";
    }
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}projects?_end=4&_start=0&_sort=id&_order=DESC&_lang=${lang}`
      )
      .then((res) => {
        setProjects(res.data);
      });
  }, [langToShow]);

  return (
    <div className={classes.header}>
      <div className={classes.leftSide}>
        <img src={"/images/armlogo.jpg"} alt="logo" />
        <div className={classes.logotext}>
          <NavLink
            to="/"
            className={classes.navlink}
            style={{ textDecoration: "none", color: "#373435" }}
          >
            <h2>{t("logoName1")}</h2>
            <h6>{t("logoName2")}</h6>
          </NavLink>
        </div>
      </div>
      <div className={classes.rightSide}>
        <NavLink to={`/about-us`}>
          <div
            className={classes.menuitem}
            onMouseEnter={handleMouseEnter1}
            onMouseLeave={handleMouseLeave1}
            onClick={handleMouseLeave1}
          >
            <span>{t("MenuItemsTitle1")}</span>
            <img src={"/images/Vector.jpg"} alt="arrow" />
            {showDropdown1 ? <DropdownBox listItems={arr1} /> : null}
          </div>
        </NavLink>

        <NavLink to={`/portfolio`}>
          <div
            className={classes.menuitem}
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
            onClick={handleMouseLeave2}
          >
            <span>{t("MenuItemsTitle2")}</span>
            <img src={"/images/Vector.jpg"} alt="arrow" />
            {showDropdown2 ? <DropdownBox listItems={arr2} /> : null}
          </div>
        </NavLink>
        <NavLink to={`/projects`}>
          <div
            className={classes.menuitem}
            onMouseEnter={handleMouseEnter3}
            onMouseLeave={handleMouseLeave3}
            onClick={handleMouseLeave3}
          >
            <span>{t("MenuItemsTitle3")}</span>

            <img src={"/images/Vector.jpg"} alt="arrow" />
            {showDropdown3 ? (
              <DropdownBox listItems={projects} dropdownType="projects" />
            ) : null}
          </div>
        </NavLink>
        <NavLink to={`/publications`}>
          <div
            className={classes.menuitem}
            onMouseEnter={handleMouseEnter4}
            onMouseLeave={handleMouseLeave4}
            onClick={handleMouseLeave4}
          >
            <span>{t("MenuItemsTitle4")}</span>

            <img src={"/images/Vector.jpg"} alt="arrow" />
            {showDropdown4 ? <DropdownBox listItems={arr4} /> : null}
          </div>
        </NavLink>
        <NavLink to={`/media`}>
          <div
            className={classes.menuitem}
            onMouseEnter={handleMouseEnter5}
            onMouseLeave={handleMouseLeave5}
            onClick={handleMouseLeave5}
          >
            <span>{t("MenuItemsTitle5")}</span>

            <img src={"/images/Vector.jpg"} alt="arrow" />
            {showDropdown5 ? <DropdownBox listItems={arr5} /> : null}
          </div>
        </NavLink>
        <NavLink to={`/news`}>
          <div className={classes.menuitem}>
            <span>{t("MenuItemsTitle6")}</span>
          </div>
        </NavLink>
      </div>

      <div className={classes.searchForm}>
        <input
          type="text"
          placeholder={t("find")}
          className={classes.input}
          onChange={handleSearchInput}
          value={inputValue}
        />
        <NavLink to={`/search-results`}>
          <img
            src={"/images/Search.png"}
            alt=""
            className={classes.searchIcon}
            onClick={handleSearchClick}
          />
        </NavLink>
      </div>
      <div
        className={`${classes.menuitem} ${classes.lang}`}
        onClick={handleLanguageChange}
      >
        {langToShow}
      </div>

      <div className={classes.mobileRightSide}>
        <img
          src={process.env.PUBLIC_URL + "/images/mobileMenuIcon.png"}
          className={classes.menuIcon}
          onClick={handleIconClick}
        />
        {showDropdownMenu ? (
          <DropdownBox
            listItems={MenuItemsArr}
            className={classes.dropdownMenu}
            onClick={() => setShowDropdownMenu(false)}
          />
        ) : null}
      </div>
    </div>
  );
}

export default withNamespaces()(Header);
