import React, { useEffect, useState } from "react";
import styles from "../Portfolio/Portfolio.module.css";
import PortfolioMainItem from "../Portfolio/PortfolioMainItem/PortfolioMainItem";
import { withNamespaces } from "react-i18next";
import Topic from "../../SharedComponents/Topic/Topic";
import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
import programs from "../../../sharedInformation/ProjectsData.service";
import Spinner from "../../SharedComponents/Spinner/Spinner";
import Helmet from "react-helmet";

function Programs({ t }) {
  const [projects, setProjects] = useState([]);
  let lang = localStorage.getItem('lang');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}projects?_sort=id&_order=DESC&_lang=${lang}`)
      .then(res => res.json())
      .then(data => {
        setProjects(data)
      })
  }, [lang])

  let configuredData = projects.map((project) => {
    let imageUrl = '';
    switch (project.categorie) {
      case 'AdaptationMechanism':
        imageUrl = "/images/programIcons/3.png";
        break;
      case 'HmgmAndCreativeGames':
        imageUrl = "/images/programIcons/5.png";
        break;
      case 'ThreeSeasSystem':
        imageUrl = "/images/programIcons/1.png";
        break;
      case 'DigitalArmenia':
        imageUrl = "/images/programIcons/2.png";
        break;
      case 'Road':
        imageUrl = "/images/programIcons/4.png";
        break;
      default:
        break;
    }
    let dataObject = {
      imageUrl: imageUrl,
      title: project.title,
      text: project.text,
      route: `/projects/${project.id}`
    }
    return dataObject;
  })

  return programs ? (
    <div style={{ marginTop: 150 }}>
      <Helmet>
        <title>{t("MenuItemsTitle3")}</title>
      </Helmet>
      <Topic topic={t("MenuItemsTitle3")} />
      <div className={styles.itemsWrapper}>
        <div className={styles.itemsContainer}>
          {configuredData.map((item) => {
            return (
              <PortfolioMainItem
                key={item.id}
                className={styles.portfolioItem}
                portFolioData={item}
              />
            );
          })}
        </div>
      </div>
      <AnalyticalBulletin />
    </div>
  )
    : (<Spinner />)
}

export default withNamespaces()(Programs);
