import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './SocialLinks.module.css';

function SocialLinks({linkedinLink, orcidLink}) {
    return (
        <div className = {classes.container}>
            <a className = {classes.instagram} href = {linkedinLink} target = "_blank" >
                <img src = "/images/socialIcons/staff/instagram.png" />
            </a>
            <a className = {classes.orcid} href = {orcidLink} target = "_blank" >
                <img src = "/images/socialIcons/staff/orcid.png" />
            </a>
        </div>
    )
}

export default SocialLinks
