import React, { useEffect, useState } from "react";
import Topic from "../../SharedComponents/Topic/Topic";
import News from "../../SharedComponents/News/News";
import PublicationList from "../../SharedComponents/PublicationList/PublicationList";
import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
import { useParams } from "react-router-dom";

import "./PolicyPaper.css";
import Spinner from "../../SharedComponents/Spinner/Spinner";
import { withNamespaces } from "react-i18next";
import Helmet from "react-helmet";

const toLeft = "<";
const toRight = ">";

function PolicyPaper({ t }) {
  const params = useParams();

  let [publications, setPublications] = useState([]);
  let [lastPublications, setLastPublications] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const [pageNumberLimit, setpageNumberLimit] = useState(4);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(4);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const [pages, setPages] = useState([]);
  let lang = localStorage.getItem("lang");

  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}news?_sort=id&type=${
        params.pubId
      }&_order=DESC&_start=${currentPage * 5 - 5}&_end=${
        currentPage * 5
      }&_lang=${lang}`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    let count = Math.ceil(result.count / itemsPerPage);
    for (let i = 1; i <= count; i++) {
      pages[i - 1] = i;
    }
    let arr = pages.splice(0, count)
    setPages(arr);
    setPublications(result.items);
  }, [lang, params, currentPage]);

  useEffect(async () => {
    let pubResult = await fetch(
      `${process.env.REACT_APP_SERVER_URL}publications?_sort=id&_order=DESC&_start=0&_end=4&_lang=${lang}`,
      {
        method: "GET",
      }
    );
    pubResult = await pubResult.json();
    setLastPublications(pubResult);
  }, [lang]);
  useEffect(() => {
    setCurrentPage(1);
  }, [params]);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };



  let topic = "";
  switch (params.pubId) {
    case "policy-paper":
      topic = t("item42");
      break;
    case "sources-of-armenian-diplomacy":
      topic = t("item43");
      break;
    case "monographs":
      topic = t("item44");
      break;
    case "annual-reports":
      topic = t("item45");
  }
  let smallPublicationList = lastPublications.slice(0, 4);

  return (
    <>
      {publications.length !== 0 ? (
        <div className="policyPaper">
          <Helmet>
            <title>{topic}</title>
          </Helmet>
          <Topic topic={topic} />
          <div className="publicationsArea">
            <div className="newsArea">
              <News newsListArray={publications} h6Content={topic} />
              <ul className="pageNumbers">
                <li>
                  <button
                    onClick={handlePrevbtn}
                    disabled={currentPage == pages[0] ? true : false}
                  >
                    {toLeft}
                  </button>
                </li>
                {renderPageNumbers}
                <li>
                  <button
                    onClick={handleNextbtn}
                    disabled={
                      currentPage == pages[pages.length - 1] ? true : false
                    }
                  >
                    {toRight}
                  </button>
                </li>
              </ul>
            </div>

            <div className="rightSide">
              <PublicationList
                className="pubArea"
                h3Content={t("MenuItemsTitle4")}
                publications={lastPublications}
              />
            </div>
          </div>
          <AnalyticalBulletin />
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default withNamespaces()(PolicyPaper);
