import React from 'react';
import classes from './ProgramBox.module.css';
import KnowMoreButton from '../../../../SharedComponents/KnowMoreButton/KnowMoreButton';
import { NavLink } from "react-router-dom";

function ProgramBox({ title, text, image, id }) {
    return (
        <div className = {classes.programBox}>
            <img  src={image} className={classes.programimg} />
            <div className={classes.title} >
                {title}
            </div>
            <div className={classes.text} >
                {text}
            </div>
            <div className={classes.buttonBox} >
                <NavLink to = {`/projects/${id}`}>
                    <KnowMoreButton />
                </NavLink>
            </div>


        </div>
    )
}

export default ProgramBox;
