import React from "react";
import styles from "../Portfolio/Portfolio.module.css";
import PortfolioMainItem from "../Portfolio/PortfolioMainItem/PortfolioMainItem";
import { withNamespaces } from "react-i18next";
import Topic from "../../SharedComponents/Topic/Topic";
import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
import Helmet from "react-helmet";

function AboutUs({ t }) {
  const aboutUsItems = [
    {
      id: 1,
      imageUrl: "/images/aboutUsIcons/councilOfTrustees.png",
      title: t("item11"),
      route: "/about-us/council-of-trustees",
    },
    {
      id: 2,
      imageUrl: "/images/aboutUsIcons/scientificCouncil.png",
      title: t("item12"),
      route: "/about-us/scientific-council",
    },
    {
      id: 3,
      imageUrl: "/images/aboutUsIcons/rules.png",
      title: t("item13"),
      route: "/about-us/rules/1",
    },
    {
      id: 4,
      imageUrl: "/images/aboutUsIcons/historOfCenter.png",
      title: t("item14"),
      route: "/about-us/history-of-the-center",
    },
    {
      id: 5,
      imageUrl: "/images/aboutUsIcons/downtownStaff.png",
      title: t("item15"),
      route: "/about-us/center-staff",
    },
    {
      id: 6,
      imageUrl: "/images/aboutUsIcons/donors.png",
      title: t("item16"),
      route: "/about-us/donors-and-partners",
    },
  ];

  return (
    <div style={{ marginTop: 150 }}>
      <Helmet>
        <title>{t("MenuItemsTitle1")}</title>
      </Helmet>
      <Topic topic={t("MenuItemsTitle1")} />
      <div className={styles.itemsWrapper}>
        <div className={styles.itemsContainer}>
          {aboutUsItems.map((item) => {
            return (
              <PortfolioMainItem
                key={item.id}
                className={styles.portfolioItem}
                portFolioData={item}
              />
            );
          })}
        </div>
      </div>
      <AnalyticalBulletin />
    </div>
  );
}

export default withNamespaces()(AboutUs);
