import { v4 as uuidv4 } from "uuid";

const programs = [
  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program1.png",
    title: "Բախում և համերաշխություն 3",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },
  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program2.png",
    title: "ՔՄՀԿ գիտխորհուրդ 3",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական ",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },
  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program3.png",
    title: "Սիրիայում հայկական...3",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },

  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program4.png",
    title: "Բախում և համերաշխություն3",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },
  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program1.png",
    title: "Բախում և համերաշխություն 2",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },
  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program2.png",
    title: "ՔՄՀԿ գիտխորհուրդ 2",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական ",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },
  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program3.png",
    title: "Սիրիայում հայկական... 2",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },

  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program4.png",
    title: "Բախում և համերաշխություն 2",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },

  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program2.png",
    title: "111",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },
  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program1.png",
    title: "222",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական ",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },
  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program4.png",
    title: "333",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },
  {
    id: uuidv4(),
    route: `programs/${uuidv4()}`,
    imageURL: "program3.png",
    title: "4444",
    text: "Ժողովածուն նախատեսված է քաղաքագետների‚ մշակութաբանների‚ սոցիոլոգների‚ ազգագրագետների‚ հայագետների, արևելագիտական, պատմական",
    sponcor: "Եվրասիա համագործակցություն հիմնադրամ",
    sponcorImageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject1: "«Հայաստանը և տարածաշրջանը» նախագիծն իրականացվել է «ՀԿ Դեպո – Հասարակական կազմակերպությունների զարգացման ծրագրի» շրջանակում` ԱՄՆ Միջազգային զարգացման գործակալության միջոցով: «Հայաստանը և տարածաշրջանը»  նախագծի արդյունքներով ստեղծված համանուն ձեռնարկը մաս է կազմել «ԵՀՀ համալսարան» խորագրի ներքո հրապարակվող ձեռնարկների շարքի՝ «Կրթություն, պատմության մեթոդաբանություն, մշակույթ և արժեքներ» (ԿՊՄՄԱ) թեմայով:\n" +
      "Ծրագիրը միտված է եղել քաղաքացիական հասարակության կառույցների և առանձին անհատների կարողությունների զարգացմանը՝ սեփական հնարավորությունները ընդլայնելու և հասարակության տարբեր խմբերի շահերին առավել արդյունավետ կերպով ծառայելու, ՔՀԿ-ների կայունության զարգացման և փոփոխություններին դիմակայելու հնարավորությունները մեծացնելու նպատակով:\n" +
      "Հայաստանը և տարածաշրջանը ձեռնարկը ստեղծվել է հաշվի առնելով քաղաքացիական հասարակության անդամների՝ ՀՀ-ի հարևան հասարակությունների, դրանց մշակութային, քաղաքական և այլ առանձնահատկությունների վերաբերյալ տեղեկատվական այս անսահման դաշտում արժանահավատ աղբյուր ունենալու կարիքը: Ձեռնարկը թույլ է տալիս խնայել ՔՀԿ-ների  ներկայացուցիչների ժամանակը և մասնագիտական հսկայածավալ գրականության փոխարեն մեկ աղբյուրից ստանալ ՀՀ կենսական շահերի տարածաշրջանին առնչվող կառուցվածքային և վերլուծական անհրաժեշտ տեղեկատվությունը:\n",
    imageSrc: process.env.PUBLIC_URL + `/images/publicationImgs/publication1.png`,
    aboutProject2: "ՀՀ-ի հարևանությամբ են գտնվում մուսուլմաններով բնակեցված երեք պետություն, որոնցից միայն Իրանի Իսլամական Հանրապետության հետ են առկա սերտ հարաբերություններ, իսկ Թուրքիայի և Ադրբեջանի հետ մինչ օրս ունենք առկախված կոնֆլիկտներ:",
    projectManager: "Դավիթ Հովհաննիսյան",
    employes: "Դավիթ Հովհաննիսյան",
    capacity: "Դավիթ Հովհաննիսյան",
    results: "Ծրագրի արդյունքում ՔՄՀԿ աշխատակիցների ջանքերով և ԵՀՀ աջակցությամբ պատրաստվել է «Հայաստանը և տարածաշրջանը» խորագրով առցանց ձեռնարկ՝ նախատեսված քաղաքացիական հասարակության ներկայացուցիչների համար",
    checkList: {
      checkListTopic: "Ձեռնարկը կազմված է հետևյալ չորս բաժինններից",
      checkListItems: [
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
        "Ամեն ինչ իրանցիների և Իրանի մասին",
      ]
    },
    programSlider: [
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",

      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/mainSliderimgs/img1.jpg",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",
      },
      {
        id: uuidv4(),
        imgUrl: process.env.PUBLIC_URL + "/images/historyOfTheCenter/historyOfTheCenter2.png",

      },
    ]
  },
];

export default programs;
