import { react, useState, useEffect } from "react";
import classes from "./ExWorkers.module.css";
import { withNamespaces } from "react-i18next";
import Spinner from "../../../SharedComponents/Spinner/Spinner";

function ExWorkers({ t, endpoint = "exworkers", header = "exWorkers" }) {
  let lang = localStorage.getItem("lang");
  let [members, setMembers] = useState([]);
  useEffect (async () => {
      let result  = await fetch(`${process.env.REACT_APP_SERVER_URL}${endpoint}?_sort=id&_order=ASC&_lang=${lang}`, {
          method: "GET"
      });
      result = await result.json();
      setMembers(result)

  }, [lang] );


  return ( members ?
    <div className={classes.container}>
      <div className={classes.header}>{t(header)}</div>
      {members.map((worker) => {
        return <div className={classes.member}>{worker.info}</div>;
      })}
    </div> : <Spinner />
  );
}

export default withNamespaces()(ExWorkers);
