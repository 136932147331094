// import styles from "./AnalyticalBulletin.module.css";
// import AnalyticalBulletinHeader from "./AnalyticalBulletinHeader/AnalyticalBulletinHeader";
import Topic from "../Topic/Topic";
import AnalyticalBulletinSlider from "./AnalyticalBulletinSlider/AnalyticalBulletinSlider";
import { withNamespaces } from "react-i18next";


function AnalyticalBulletin({t}) {
  return (
    <div>
      <Topic topic = {t("item41")} />
      <AnalyticalBulletinSlider />
    </div>
  );
}

export default withNamespaces()(AnalyticalBulletin);
