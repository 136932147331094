import { React, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
// import titles from "./RulesTitles.service";

import styles from "./RulesTitles.module.css";

import { withNamespaces } from "react-i18next";

function RulesTitles() {


  let [rules, setRules] = useState([]);

  let lang = localStorage.getItem("lang");
  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}rules?_sort=id&_order=ASC&_lang=${lang}`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    setRules(result);
  }, [lang]);

  const params = useParams();



  return (
    <div>
      <ul className={styles.list}>
        {rules.map((rule, index) => {
          return (
            <li
              key={index}
              className={
                params.rulesId == rule.id
                  ? `${styles.title} ${styles.activeBorder}`
                  : `${styles.title}`
              }
            >
              <NavLink
                to={`/about-us/rules/${rule.id}`}
                className={
                  params.rulesId == rule.id
                    ? `${styles.titleLink} ${styles.activeLink}`
                    : `${styles.titleLink}`
                }
              >
                <p>{rule.title}</p>
              </NavLink>
            </li>
          );
        })}
      </ul>

    </div>
  );
}

export default withNamespaces()(RulesTitles);
