// import slideshowimg from '../../../../../public/images/mainsliderimg.jpg'
import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import Spinner from "../../../SharedComponents/Spinner/Spinner";

import classes from "./SlideShowMain.module.css";

// import dataSlider from "./dataSlider";

function SlideShowMain() {
  const [lastNews, setLastNews] = useState([]);
  let lang = localStorage.getItem("lang");

  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}news?type=news&_sort=id&_order=DESC&_start=0&_end=3&_lang=${lang}`,
      {
        method: "GET",
        // params: {" _limit": "3" }
      }
    );
    result = await result.json();
// console.log("result.items: ", result.items);
    setLastNews(result.items);
  }, [lang]);

  const [slideIndex, setSLideIndex] = useState(1);

  const moveLine = (index) => {
    setSLideIndex(index);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const newSlideIndex = slideIndex == 3 ? 1 : slideIndex + 1;
      setSLideIndex(newSlideIndex);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, [slideIndex]);

  return lastNews.length !== 0 ? (
    <div className={classes.slider}>
      {lastNews.map((obj, index) => {
        return (
          <div
            key={obj._id}
            className={
              slideIndex === index + 1
                ? `${classes.slide} ${classes.activAnim}`
                : classes.slide
            }
          >
            <img src={`${obj.mainImage}`} />
            <div className={classes.blackFon}></div>
            <NavLink className={classes.slideText} to={`/news/${obj.id}`}>
              <div className={classes.title}>{obj.title}</div>
              <div className={classes.text}>{obj.previewText}</div>
            </NavLink>
            <div className={classes.slideNumbering}>
              <div className={classes.scaled}>{`${slideIndex}/`}</div>
              <div>{`${lastNews.length}`}</div>
            </div>
          </div>
        );
      })}

      <div className={classes.linesContainer}>
        {Array.from({ length: 3 }).map((item, index) => (
          <div
            onClick={() => moveLine(index + 1)}
            className={
              slideIndex === index + 1
                ? `${classes.lineActiv} ${classes.line}`
                : classes.line
            }
          ></div>
        ))}
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default SlideShowMain;
