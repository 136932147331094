import React, { useState } from "react";
import classes from "./HistoryOfCenterSlider.module.css";
import dataSliderHistory from "./HistoryOfCenter.service";

function HistoryOfCenterSlider({ data }) {
  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = () => {
    if (slideIndex != data.length) {
      setSlideIndex(slideIndex + 1);
    } else {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex != 1) {
      setSlideIndex(slideIndex - 1);
    } else {
      setSlideIndex(data.length);
    }
  };

  return (
    <div className={classes.imgContainer}>
      {data.map((img, ind) => {
        return (
          <div
            key={ind}
            className={
              slideIndex == ind + 1
                ? `${classes.slide} ${classes.activAnim}`
                : classes.slide
            }
          >
            <img src={img} />
          </div>
        );
      })}

      <div
        className={`${classes.btnSlider} ${classes.prev}`}
        onClick={prevSlide}
      >
        <img src="/images/leftWhite.png" />
      </div>

      <div
        className={`${classes.btnSlider} ${classes.next}`}
        onClick={nextSlide}
      >
        <img src="/images/rightWhite.png" />
      </div>

      <div className={classes.dotsContainer}>
        {data.map((img, ind) => {
          return (
            <div
              key={ind}
              className={
                slideIndex == ind + 1
                  ? `${classes.dot} ${classes.activDot}`
                  : classes.dot
              }
            >
            </div>
          );
        })}
      </div>
    </div>
  );

  // <img src="/images/historyOfTheCenter/historyOfTheCenter2.png"></img>
}

export default HistoryOfCenterSlider;
