import React from 'react';
import "./Publication.css";

function Publication(props) {
    return (
        <div className="publication">
            <img className="image" src={props.imageSrc} />
            <h4 className="h4">{props.h4Content}</h4>
        </div>
    );
}

export default Publication;