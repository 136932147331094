import React from "react";
// import { Helmet } from "react-helmet";
import "./NewsItem.css";

function NewsItem(props) {
  return (
    <div className="newsItem">
      {/* <Helmet>
        <meta property="og:title" content={props.h2Content} />
        <meta property="og:image" content={props.imageSrc} />
        <meta name="description" content={props.pContent} />
      </Helmet> */}
      {props.imageSrc ? (
        <img className="newsItemImage" src={props.imageSrc} alt="" />
      ) : null}
      <div className="newsRightContent">
        <h6 className="newsItemH6">{props.h6Content}</h6>
        <h2 className="newsItemH2">{props.h2Content}</h2>
        <p className="newsItemP">{props.pContent} </p>
        <p className="newsItemDate">{props.date} </p>
      </div>
    </div>
  );
}

export default NewsItem;
