import { v4 as uuidv4 } from "uuid";

const portfolioItems = [
  {
    id: uuidv4(),
    title: "«ԵՐԵՔ ԾՈՎԵՐԻ ՀԱՄԱԿԱՐԳ» ՏԵՍՈՒԹՅՈՒՆ",
    imageURL: "threesees.png",
    itemClass: "item1",
    route: "/portfolio/three-sea-theory",
  },
  {
    id: uuidv4(),
    title: "«ԱԴԱՊՏԱՑԻՈՆ ՄԵԽԱՆԻԶՄՆԵՐ» ՏԵՍՈՒԹՅՈՒՆ",
    imageURL: "adaptation.png",
    itemClass: "item2",
    route: "/portfolio/adaptation-theory",
  },  
  {
    id: uuidv4(),
    title: "«ՑԱՆՑԱՊԵՏՈՒԹՅՈՒՆ» ՏԵՍՈՒԹՅՈՒՆ",
    imageURL: "networkCountry.png",
    itemClass: "item3",
    route: "/portfolio/network-country",
  },
  {
    id: uuidv4(),
    title: "ԹՎԱՅԻՆ ՀԱՅԵՐԵՆ",
    imageURL: "digitalarm.png",
    itemClass: "item4",
    route: "/portfolio/digital-armenian",
  },
  {
    id: uuidv4(),
    title: "ՀՄԳՄ ԵՎ ԿՐԵԱՏԻՎ ԽԱՂԵՐ",
    imageURL: "creativeGames.png",
    itemClass: "item5",
    route: "/portfolio/creative-games",
  },
];

export default portfolioItems;
