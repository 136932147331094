import React from 'react';
import classes from './KnowMoreButton.module.css';
import { withNamespaces } from 'react-i18next';

function KnowMoreButton({t}) {
    return (
        <div className = {classes.moreButton} >
            <span className = {classes.buttonText}>{t("knowMore")}</span>
            <img src={ process.env.PUBLIC_URL + "/images/arrow-right-blue.png"} className ={classes.buttonimg} />
        </div>
    )
}

export default withNamespaces()(KnowMoreButton);
