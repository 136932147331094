import RulesContent from "./RulesContent/RulesContent";
import RulesTitles from "./RulesTitles/RulesTitles";

import Topic from "../../SharedComponents/Topic/Topic";
import Spinner from "../../SharedComponents/Spinner/Spinner";
import { NavLink } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import styles from "./Rules.module.css";
import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
import Helmet from "react-helmet";

function Rules({t}) {
  return (
    <div  style = {{marginTop: 150}}>
      <Helmet>
        <title>{t("item13")}</title>
      </Helmet>
      <div className={styles.wrapper}>
        <Topic topic ={t("item13")} />
        <div className={styles.container}>
          <div className={styles.rulesTitles}>
            <RulesTitles />
          </div>
          <div className={styles.rulesContent}>
            <RulesContent />
          </div>
        </div>
      </div>
      <AnalyticalBulletin />
    </div>
  );
}

export default withNamespaces()(Rules);
