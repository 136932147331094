import React, { useState } from "react";
import classes from "./Contact.module.css";
import MapComponent from "./MapComponent/MapComponent";
import validator from "validator";
import { withNamespaces } from "react-i18next";

function Contact({ t }) {
  const [values, setValues] = useState({
    name: "",
    mail: "",
    message: "",
  });

  const handleNameInput = (e) => {
    setValues({ ...values, name: e.target.value });
  };
  const [emailError, setEmailError] = useState("");
  const handleMailInput = (e) => {
    let mail = e.target.value;
    // if (validator.isEmail(mail)) {
    //   setEmailError("");
    // } else {
    //   setEmailError("սխալ էլեկտրոնային հասցե");
    // }
    setValues({ ...values, mail: mail });
  };
  const handleMessageInput = (e) => {
    setValues({ ...values, message: e.target.value });
  };

  const saveFormData = async () => {
    // console.log(values);
    if (validator.isEmail(values.mail)) {
      setEmailError("");
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}users`, {
        method: "POST",
        headers: {
          "Content-type": "application/json;  charset=UTF-8",
        },
        body: JSON.stringify(values),
      });
      if (response.status !== 200) {
        throw new Error(`Request failed: ${response.status}`);
      }
      setValues({
        name: "",
        mail: "",
        message: "",
      });
    } else {
      let errorMessage = t("emailErrorMessage");
      setEmailError(errorMessage);
    }
  };
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await saveFormData();
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <div className={classes.contactContainer}>
      <div className={classes.map}>
        <MapComponent />
      </div>
      <div className={classes.contactForm}>
        <div className={classes.formHeader}>{t("feedback")}</div>
        <form className={classes.formContainer} onSubmit={onSubmitHandler}>
          <label htmlFor="fname">{t("name")}</label>
          <input
            onChange={handleNameInput}
            type="text"
            className={`${classes.nameinput} ${classes.inputbox}`}
            value={values.name}
            required
          />
          <label htmlFor="mail">{t("mail")}</label>
          <input
            onChange={handleMailInput}
            type="mail"
            className={`${classes.mailinput} ${classes.inputbox}`}
            value={values.mail}
            required
          />

          <label htmlFor="message">{t("message")}</label>
          <textarea
            onChange={handleMessageInput}
            type="text"
            className={`${classes.messageinput} ${classes.inputbox}`}
            value={values.message}
            required
          />
          <div
            style={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            {emailError}
          </div>
          <input
            type="submit"
            value={t("send")}
            className={classes.submitbutton}
          />
        </form>
      </div>
    </div>
  );
}

export default withNamespaces()(Contact);
