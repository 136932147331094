import { React, useState, useEffect } from "react";
import Topic from "../../../SharedComponents/Topic/Topic";
import PublicationItem from "./PublicationItem/PublicationItem";
import classes from "./Publications.module.css";

import Spinner from "../../../SharedComponents/Spinner/Spinner";
import { NavLink } from "react-router-dom";

import { withNamespaces } from "react-i18next";

function Publications({ t }) {
  let [lastPublications, setLastPublications] = useState([]);

  let lang = localStorage.getItem("lang");
  const [imgSlideIndex, setImgSlideIndex] = useState(0);
  useEffect(async () => {
    let pubResult = await fetch(
      `${process.env.REACT_APP_SERVER_URL}publications?_sort=id&_order=DESC&_start=0&_end=9&_lang=${lang}`,
      {
        method: "GET",
      }
    );
    pubResult = await pubResult.json();
    setLastPublications(pubResult);
    setCurrentArr(pubResult.slice(0, 3));
    console.log(lang);
  }, [lang]);

  const [currentArr, setCurrentArr] = useState([]);
  const showNext = () => {
    if (imgSlideIndex == 8) {
      setCurrentArr(lastPublications.slice(0, 3));
      setImgSlideIndex(0);
    } else if ((imgSlideIndex + 3) % 3 == 2) {
      setCurrentArr(
        lastPublications.slice(imgSlideIndex + 1, imgSlideIndex + 4)
      );
      setImgSlideIndex(imgSlideIndex + 1);
    } else {
      setImgSlideIndex(imgSlideIndex + 1);
    }
  };

  const showPrev = () => {
    if (imgSlideIndex == 0) {
      setCurrentArr(lastPublications.slice(6, 9));
      setImgSlideIndex(8);
    } else if ((imgSlideIndex + 3) % 3 == 0) {
      setCurrentArr(lastPublications.slice(imgSlideIndex - 3, imgSlideIndex));
      setImgSlideIndex(imgSlideIndex - 1);
    } else {
      setImgSlideIndex(imgSlideIndex - 1);
    }
  };

  return (
    <>
      <Topic topic={t("MenuItemsTitle4")} />
      {lastPublications.length === 9 ? (
        <div className={classes.publContainer}>
          <div className={classes.arrowsContainer}>
            <div className={classes.arrowLeft} onClick={showPrev}>
              <img src={process.env.PUBLIC_URL + "/images/left.png"} alt="" />
            </div>
            <div className={classes.arrowRight} onClick={showNext}>
              <img src={process.env.PUBLIC_URL + "/images/right.png"} alt="" />
            </div>
          </div>

          <div className={classes.sliderContainer}>
            <div className={classes.sliderLeft}>
              {/* {console.log(currentArr)} */}
              {currentArr.map((publication) =>
                publication.pdf ? (
                  <a href={`${publication.pdf}`} target="_blank">
                    <PublicationItem
                      key={publication["id"]}
                      imgUrl={`${publication["mainImage"]}`}
                      header={publication["title"]}
                      text={publication["previewText"]}
                    />
                  </a>
                ) : (
                  <NavLink to={`/news/${publication._id}`}>
                    <PublicationItem
                      key={publication["id"]}
                      imgUrl={`${publication["mainImage"]}`}
                      header={publication["title"]}
                      text={publication["previewText"]}
                      linkTo={`/news/${publication.id}`}
                    />
                  </NavLink>
                )
              )}

              {/* {console.log(lastPublications)} */}
            </div>

            {lastPublications[imgSlideIndex].pdf ? (
              <div className={classes.sliderRight}>
                <a
                  href={`${lastPublications[imgSlideIndex].pdf}`}
                  target="_blank"
                  className={classes.link}
                >
                  <div className={classes.imgContainer}>
                    <img
                      src={`${lastPublications[imgSlideIndex]["mainImage"]}`}
                      className={classes.publImg}
                    />
                    <div className={classes.blackFon}></div>

                    <div className={classes.publHeader}>
                      {lastPublications[imgSlideIndex]["title"]}
                    </div>
                    {/* <div className={classes.publText}>
                    {lastPublications[imgSlideIndex]["previewText"]}
                  </div> */}
                  </div>
                </a>
              </div>
            ) : (
              <NavLink
                to={`/news/${lastPublications[imgSlideIndex].id}`}
                className={classes.sliderRight}
              >
                <div className={classes.imgContainer}>
                  <img
                    src={`${lastPublications[imgSlideIndex]["mainImage"]}`}
                    className={classes.publImg}
                  />
                  <div className={classes.blackFon}></div>

                  <div className={classes.publHeader}>
                    {lastPublications[imgSlideIndex]["title"]}
                  </div>
                  {/* <div className={classes.publText}>
                    {lastPublications[imgSlideIndex]["previewText"]}
                  </div> */}
                </div>
              </NavLink>
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default withNamespaces()(Publications);
