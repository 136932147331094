import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import portfolioDataList from "./PortfolioDetailsPage.service";

import styles from "./PortfolioDetailsPage.module.css";
// import PortfolioDetailsPageContent from "./PortfolioDetailsPageContent/PortfolioDetailsPageContent";
import PortfolioDetailsPageRightMenu from "./PortfolioDetailsPageRightMenu/PortfolioDetailsPageRightMenu";
import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
import Spinner from "../../SharedComponents/Spinner/Spinner";
import Topic from "../../SharedComponents/Topic/Topic";
import { withNamespaces } from "react-i18next";
import Helmet from "react-helmet";

function PortfolioDetailsPage() {
  const params = useParams();
  const [currentData, setCurrentData] = useState({});
  const [type, setType] = useState("");

  let lang = localStorage.getItem("lang");
  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}theories/${params.portfolioId}?_lang=${lang}`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    setCurrentData(result);
    switch (params.portfolioId) {
      case "1":
        setType("AdaptationMechanism");
        break;
      case "2":
        setType("ThreeSeasSystem");
        break;
      case "3":
        setType("Road");
        break;
      case "4":
        setType("DigitalArmenia");
        break;
      case "5":
        setType("HmgmAndCreativeGames");
        break;
      default:
        setType("ff");
    }
  }, [lang, params]);



  const createMarkup = () => {
    return { __html: currentData.markedText };
  };

  return currentData ? (
    <div style={{ marginTop: 150 }}>
      <Helmet>
        <title>{currentData.title}</title>
      </Helmet>
      <Topic topic={currentData.title} />
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div
              dangerouslySetInnerHTML={createMarkup()}
              className={styles.content}
            >
              {/* <PortfolioDetailsPageContent contentData={currentData}/> */}
            </div>
            <div className={styles.rightMenu}>
              {type ? <PortfolioDetailsPageRightMenu projectType={type} /> : <Spinner />}
            </div>
          </div>
        </div>
      </div>
      <AnalyticalBulletin />
    </div>
  ) : (
    <Spinner />
  );
}

export default withNamespaces()(PortfolioDetailsPage);
