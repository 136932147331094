import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import classes from "./NewsDetails.module.css";
import Topic from "../../SharedComponents/Topic/Topic";
import PublicationList from "../../SharedComponents/PublicationList/PublicationList";
import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
import { withNamespaces } from "react-i18next";

function NewsDetails({ t }) {
  const params = useParams();
  let [publication, setPublication] = useState([]);
  let [lastPublications, setLastPublications] = useState([]);

  let lang = localStorage.getItem("lang");
  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}news/${params.newsId}?_lang=${lang}`,
      {
        method: "GET",
      }
    );
    result = await result.json();

    setPublication(result);
  }, [lang]);

  useEffect(async () => {
    let pubResult = await fetch(
      `${process.env.REACT_APP_SERVER_URL}publications?_sort=id&_order=DESC&_start=0&_end=4&_lang=${lang}`,
      {
        method: "GET",
      }
    );
    pubResult = await pubResult.json();
    setLastPublications(pubResult);
  }, [lang]);

  const createMarkup = () => {
    // console.log(">>> ", publication);
    return { __html: publication.markedText };
  };

  return (
    <div className={classes.newsDetails}>
      <Helmet>
        <title>{publication.title}</title>
        <meta property="og:title" content={publication.title} />
        <meta property="og:image" content={publication.mainImage} />
        <meta name="description" content={publication.previewText} />
      </Helmet>
      <Topic topic={publication.title} />
      <div className={classes.container}>
        <div
          dangerouslySetInnerHTML={createMarkup()}
          className={classes.newsContainer}
        ></div>
        <div className={classes.rightSide}>
          <PublicationList
            h3Content={t("MenuItemsTitle4")}
            publications={lastPublications}
          />
        </div>
      </div>
      <AnalyticalBulletin />
    </div>
  );
}

export default withNamespaces()(NewsDetails);
