import React from 'react';
import SlideShowMain from './SlideShowMain/SlideShowMain';
import PortfolioDetails from './PortfolioDetails/PortfolioDetails';
import ProgramsMain from './ProgramsMain/ProgramsMain';
import Publications from './Publications/Publications';
import AnalyticalBulletin from '../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin';
import Contact from './Contact/Contact';
import Helmet from 'react-helmet';

function Home() {
    return (<>
        <Helmet>
            <title>ՔՄՀԿ Հիմնադրամ</title>
        </Helmet>
        <SlideShowMain />
        <PortfolioDetails />
        <ProgramsMain />
        <Publications />
        <AnalyticalBulletin />
        <Contact />
    </>
    )
}
export default Home;