import { react, useState, useEffect } from "react";
import Topic from "../../SharedComponents/Topic/Topic";
import Contact from "../Home/Contact/Contact";
import { withNamespaces } from "react-i18next";
import styles from "./HistoryOfCenter.module.css";
import HistoryOfCenterSlider from "./HistoryOfCenterSlider/HistoryOfCenterSlider";
import Spinner from "../../SharedComponents/Spinner/Spinner";
import Helmet from "react-helmet";

function HistoryOfCenter({ t }) {
  let lang = localStorage.getItem("lang");
  let [info, setInfo] = useState({});
  useEffect(async () => {
    let result = await fetch(
      `${process.env.REACT_APP_SERVER_URL}history?_lang=${lang}`,
      {
        method: "GET",
      }
    );
    result = await result.json();
    // console.log(result);
    setInfo(result[0]);
  }, [lang]);

  return info ? (
    <div style={{ marginTop: 150 }}>
      <Helmet>
        <title>{t("item14")}</title>
      </Helmet>
      <Topic topic={t("item14")} />
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.aboutCenterContainer}>
              <div className={styles.textArea}>
                <p className={styles.textHeader}>{t("aboutCenter")}</p>
                <p className={styles.aboutCenterText}>{info.aboutText}</p>
              </div>
              <div className={styles.imgArea}>
                <img
                  className={styles.aboutCenterImage}
                  src={info.mainImage}
                ></img>
              </div>
            </div>
            <div className={styles.possibilitiesContainer}>
              <div className={styles.leftText}>
                <p className={styles.textHeader}>{t("opportunities")}</p>
                <p className={styles.aboutCenterText}>
                  {info.possibilitiesText}
                </p>
              </div>
              <div className={styles.rightText}>
                <div className={styles.row1}>
                  <div className={styles.box}>
                    <p className={styles.boxHeader}>{t("value")}</p>
                    <p className={styles.boxText}>{info.foundationText}</p>
                  </div>
                  <div className={styles.box}>
                    <p className={styles.boxHeader}>{t("culture")}</p>
                    <p className={styles.boxText}>{info.civilizedText}</p>
                  </div>
                </div>
                <div className={styles.row2}>
                  <div className={styles.box}>
                    <p className={styles.boxHeader}>{t("civilization")}</p>
                    <p className={styles.boxText}>{info.researchesText}</p>
                  </div>
                  <div className={styles.box}>
                    <p className={styles.boxHeader}>{t("institutions")}</p>
                    <p className={styles.boxText}>{info.culturalText}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sliderContainer}>
          {info.sliderImages ? (
            <HistoryOfCenterSlider data={info.sliderImages} />
          ) : (
            <Spinner />
          )}
        </div>
        <Contact />
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default withNamespaces()(HistoryOfCenter);
