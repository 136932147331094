import { NavLink } from "react-router-dom";
import KnowMoreButton from "../../../SharedComponents/KnowMoreButton/KnowMoreButton";
// import portfolioDataList from "../../PortfolioDetailsPage/PortfolioDetailsPage.service";
import styles from "./PortfolioMainItem.module.css";

function PortfolioMainItem({ portFolioData }) {
  return (
    <div className={styles.portfolioCard}>
      <div className={styles.cardContainer}>
        <div className={styles.imgContainer}>
          <img className={styles.img} src={portFolioData.imageUrl}></img>
        </div>
        <p className={styles.title}>{portFolioData.title}</p>
        <p className={styles.text}>{portFolioData.text}</p>
        <div className={styles.knowMoreButton}>
          {portFolioData.route == "/publications/analytical-bulletin" ? (
            <a href="http://analytical-bulletin.cccs.am/">
              <KnowMoreButton />
            </a>
          ) : (
            <NavLink to={portFolioData.route}>
              <KnowMoreButton />
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
}

export default PortfolioMainItem;
