import React from "react";
import styles from "../Portfolio/Portfolio.module.css";
import PortfolioMainItem from "../Portfolio/PortfolioMainItem/PortfolioMainItem";
import { withNamespaces } from "react-i18next";
import Topic from "../../SharedComponents/Topic/Topic";
import AnalyticalBulletin from "../../SharedComponents/AnalyticalBulletin/AnalyticalBulletin";
import Helmet from "react-helmet";


function Announcements({ t }) {
  const announcements = [
    {
      id: 1,
      imageUrl: "/images/announcementIcons/icon1.png",
      title: t("item41"),
      route: "/publications/analytical-bulletin",
    },
    {
      id: 2,
      imageUrl: "/images/announcementIcons/icon2.png",
      title: t("item42"),
      route: "/publications/policy-paper",
    },
    {
      id: 3,
      imageUrl: "/images/announcementIcons/icon3.png",
      title: t("item43"),
      route: "/publications/sources-of-armenian-diplomacy",
    },
    {
      id: 4,
      imageUrl: "/images/announcementIcons/icon4.png",
      title: t("item44"),
      route: "/publications/monographs",
    },
    {
      id: 5,
      imageUrl: "/images/announcementIcons/icon4.png",
      title: t("item45"),
      route: "/publications/annual-reports",
    },
  ];

  return (
    <div style={{ marginTop: 150 }}>
      <Helmet>
        <title>{t("MenuItemsTitle4")}</title>
      </Helmet>
      <Topic topic={t("MenuItemsTitle4")} />
      <div className={styles.itemsWrapper}>
        <div className={styles.itemsContainer}>
          {announcements.map((item) => (
            <PortfolioMainItem
              key={item.id}
              className={styles.portfolioItem}
              portFolioData={item}
            />
          ))}
        </div>
      </div>
      <AnalyticalBulletin />
    </div>
  );
}

export default withNamespaces()(Announcements);
